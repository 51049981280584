import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  EnergyType,
  firstLetterLowerCase,
  GainType,
  Initiative,
  InitiativeInvestment,
  InitiativeStatus,
  InitiativeType,
  InvestmentStatus,
} from "@structs";
import { InitiativeService, OfflineService } from "@services";
import { TranslateService } from "@ngx-translate/core";
import { DecimalPipe } from "@angular/common";
import { CurrencyPipe } from "../../../../pipes/currency/currency.pipe";
import { IonItemSliding } from "@ionic/angular";

@Component({
  selector: "app-action-list",
  templateUrl: "./action-list.component.html",
  styleUrls: ["./action-list.component.scss"],
})
export class ActionListComponent implements OnInit {
  @Input() initiatives: Initiative[];
  @Input() showUnlinkButton: boolean = false;

  @Output() initiativeClicked = new EventEmitter<Initiative>();
  @Output() initiativeDeleteClicked = new EventEmitter<Initiative>();
  @Output() initiativeUnlinkClicked = new EventEmitter<Initiative>();

  private initiativeStatuses: InitiativeStatus[] = [];
  private initiativeTypes: InitiativeType[] = [];
  private energyTypes: EnergyType[] = [];
  private investmentStatuses: InvestmentStatus[] = [];

  constructor(
    private offlineService: OfflineService,
    private translate: TranslateService,
    private decimalPipe: DecimalPipe,
    private currencyPipe: CurrencyPipe,
    private initiativeService: InitiativeService
  ) {}

  ngOnInit() {
    this.offlineService.getConfig("initiativeStatus").subscribe(statuses => {
      this.initiativeStatuses = statuses;
    });
    this.offlineService.getConfig("initiativeTypes").subscribe(types => {
      this.initiativeTypes = types;
    });
    this.offlineService.getConfig("energyTypes").subscribe(types => {
      this.energyTypes = types;
    });
    this.offlineService.getConfig("investmentStatus").subscribe(investmentStatuses => {
      this.investmentStatuses = investmentStatuses;
    });
  }

  public getStatusName(statusId: number, year: number | string): string {
    let statusLabel = "";
    if (statusId && this.initiativeStatuses.length > 0) {
      const status = this.initiativeStatuses.find(s => s.id === statusId);
      if (status) {
        statusLabel = status.name;
        if (status.is_planned) {
          statusLabel += " " + this.translate.instant("for") + " " + year;
        }
      }
    }
    return statusLabel;
  }

  public getInitiativeType(typeId: number): string {
    if (typeId && this.initiativeTypes.length > 0) {
      const type = this.initiativeTypes.find(t => t.id === typeId);
      return type.name;
    } else {
      return "";
    }
  }

  public getExpectedGainLabel(initiative: Initiative): string {
    const numberOfGains = initiative.gains?.length || 0;
    if (numberOfGains === 0) {
      return this.translate.instant("No gain");
    }
    const gains = initiative.gains || [],
      totalGainkWh = gains.reduce((acc, gain) => acc + gain.theoretical_savings_full_year, 0),
      totalGainCO2 = gains.reduce((acc, gain) => acc + gain.theoretical_greenhouse_gas_savings_full_year, 0),
      energyTypeIds = gains.map(gain => gain.energy_type_id),
      energyTypes = this.energyTypes.filter(t => energyTypeIds.includes(t.id)),
      initiativeType = this.initiativeTypes.find(t => t.id === initiative.initiative_type),
      gainLabel = this.translate.instant("Expected gain");
    if (initiativeType?.gain_type === GainType.ENERGY) {
      return gainLabel + " : " + this.getConsumptionLabel(totalGainkWh);
    }
  }

  private getConsumptionLabel(savings: number): string {
    return this.decimalPipe.transform(savings, "1.0-0") + " kWh / " + this.translate.instant("year");
  }

  public getColorFromImpact(initiative: Initiative): string {
    return;
    // todo
    // const savings = initiative.emission_category?.is_calculated
    //   ? initiative.theoretical_savings_full_year
    //   : initiative.theoretical_greenhouse_gas_savings_full_year;
    // if (savings >= 0) {
    //   return "dark-green";
    // } else {
    //   return "danger";
    // }
  }

  public getInvestmentLabel(investment: InitiativeInvestment): string {
    let price = 0;
    const status = this.investmentStatuses.find(s => s.id === investment.status);
    const slices = this.initiativeService.getInitiativeInvestmentSlices(investment);
    slices.forEach(slice => {
      if (typeof slice.price === "number") {
        price += slice.price;
      } else if (typeof slice.price === "string") {
        price += parseFloat(slice.price);
      }
    });

    return (
      this.currencyPipe.transform(price) +
      " " +
      (status ? firstLetterLowerCase(status.name) : "") +
      " " +
      this.translate.instant("for") +
      " " +
      investment.final_schedule_to
    );
  }

  public initiativeUnlink(initiative: Initiative, item: IonItemSliding) {
    item.close();
    this.initiativeUnlinkClicked.emit(initiative);
  }

  public initiativeDelete(initiative: Initiative, item: IonItemSliding) {
    item.close();
    this.initiativeDeleteClicked.emit(initiative);
  }
}
