<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button>
        <app-back-button [modalMode]="modalMode"></app-back-button>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <ng-container *ngIf="!priceSheet?.is_default">{{ "Price sheet" | translate }}</ng-container>
      <ng-container *ngIf="priceSheet?.is_default">{{ "Default price sheet" | translate }}</ng-container>
    </ion-title>
    <ion-buttons slot="end"></ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <loader *ngIf="loading"></loader>
  <ion-list lines="full" *ngIf="!loading">
    <!-- PRICE SHEET LABEL -->
    <input-item [legend]="'Label' | translate" [labelClass]="'price-sheet'">
      <ion-textarea type="text" placeholder="-" [(ngModel)]="priceSheet.label" maxlength="500" readonly></ion-textarea>
    </input-item>

    <!-- ASSET TYPE -->
    <legend-item [legend]="'Asset type' | translate" [labelClass]="'price-sheet'">
      <span *ngIf="assetType">{{ assetType?.name }}</span>
      <span *ngIf="!assetType">-</span>
    </legend-item>

    <!-- UNIT PRICE -->
    <legend-item [legend]="'Unit price' | translate" [showArrow]="false" [labelClass]="'price-sheet'">
      <span *ngIf="priceSheet.unit_price">
        {{ formatPrice(priceSheet.unit_price) }}
        <span *ngIf="priceSheet.quantity_unit">/ {{ priceSheet.quantity_unit.name }}</span>
      </span>
      <span *ngIf="!priceSheet.unit_price">-</span>
    </legend-item>

    <!-- Budget Scope -->
    <legend-item [legend]="'Budget scope' | translate" [showArrow]="false" [labelClass]="'price-sheet'">
      {{ priceSheet.budgetScope?.name || "-" }}
    </legend-item>

    <!-- QUANTITY -->
    <!-- <input-item legend="{{ 'Quantity' | translate }}" [labelClass]="'price-sheet'">
      <span>{{ priceSheet.quantity | number }}</span>
      <span *ngIf="!priceSheet.quantity">-</span>
    </input-item> -->

    <!-- SOURCE (AUTHOR + CORPORATE) -->
    <legend-item
      [legend]="'Source' | translate"
      [showArrow]="false"
      [labelClass]="'price-sheet'"
      *ngIf="priceSheet.author">
      <span>{{ priceSheet.author | username }}</span>
    </legend-item>

    <!-- YEAR -->
    <legend-item
      [legend]="'Year' | translate"
      [showArrow]="false"
      [labelClass]="'price-sheet'"
      *ngIf="priceSheet?.year">
      <span>{{ priceSheet.year }}</span>
    </legend-item>

    <!-- COMMENTS -->
    <input-item [legend]="'Comments' | translate" [labelClass]="'price-sheet'">
      <textarea textareaElastic [(ngModel)]="priceSheet.comments" placeholder="-" [readOnly]="!canEdit"></textarea>
    </input-item>

    <!-- REGION -->
    <legend-item [legend]="'Region' | translate" [showArrow]="false" [labelClass]="'price-sheet'">
      <span>{{ priceSheet.region || "-" }}</span>
    </legend-item>

    <!-- COUNTRY -->
    <legend-item [legend]="'Country' | translate" [showArrow]="false" [labelClass]="'price-sheet'">
      <span>{{ priceSheet.country || "-" }}</span>
    </legend-item>

    <!-- LAST MODIFICATION DATE -->
    <legend-item
      [legend]="'Last modified' | translate"
      [showArrow]="false"
      [labelClass]="'price-sheet'"
      *ngIf="priceSheet.modified_on">
      <span>{{ priceSheet.modified_on | date }}</span>
    </legend-item>
  </ion-list>
</ion-content>
