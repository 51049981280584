import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Picture } from "@structs";
import { ChaptersGroup, RoadmapChaptersService } from "../../services/roadmap-chapters.service";
import { Perimeter, Zone } from "../../structs/assets";
import { AccessLevel, Roadmap, Theme } from "../../structs/roadmap";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "roadmap-list-item",
  templateUrl: "./roadmap-list-item.component.html",
  styleUrls: ["./roadmap-list-item.component.scss"],
})
export class RoadmapListItemComponent implements OnInit {
  @Input() public chaptersGroup: ChaptersGroup<Perimeter | Theme | Zone | AccessLevel> = null;
  @Input() public perimeter: Perimeter = null;
  @Input() public showPicture: boolean = true;
  @Input() public roadmap: Roadmap = null;
  @Input() public query: string = "";
  @Input() public forceLabel: string = "";
  @Input() public subTitle: string = "";
  @Input() public index: number = 0;
  @Input() public itemOptions = null;
  @Input() public groupName;
  @Input() public perimeterPrefix: string;
  @Input() public padding: string = "";

  @Input() public showChevronButtons: boolean = false;
  @Input() public hasChildren: boolean = false;
  @Input() public isCollapsed: boolean = false;
  @Input() public isChild: boolean = false;
  @Input() public color: string = "";
  @Input() public showAudits: boolean = false;
  @Output() public toggleCollapse = new EventEmitter();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onItemClicked = new EventEmitter<any>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onPictureClicked = new EventEmitter<any>();

  public itemLabel: string = "";
  public pictureEmpty: Picture = new Picture(-1, null, null, null, null, null, null);
  public pieChartPercentage: number = null;

  constructor(private translate: TranslateService, private modalCtrl: ModalController) {}

  public ngOnInit(): void {
    if (this.forceLabel) {
      this.itemLabel = this.forceLabel;
    } else {
      this.itemLabel = this.chaptersGroup.group.name;
    }
  }

  public openChaptersGroup(chaptersGroup: ChaptersGroup<Perimeter | Theme | Zone>): void {
    let title: string;
    const chapterName = this.perimeterPrefix + " " + chaptersGroup.group.name;
    if (this.groupName) {
      title = this.groupName + " > " + chapterName;
    } else {
      title = chapterName;
    }
    let params: any = {
      chaptersGroup: chaptersGroup,
      titleTheme: title,
      roadmap: this.roadmap,
      perimeter: RoadmapChaptersService.isPerimeterChaptersGroup(chaptersGroup) ? chaptersGroup.group : this.perimeter,
      multiPerimeter: this.perimeter,
      chapters: chaptersGroup.chapters,
      previousQuery: this.query,
    };
    this.onItemClicked.emit(params);
  }

  public toggleMultiPerimeterChildren(): void {
    this.toggleCollapse.emit();
  }

  /**
   * Get the percentage of acknowledged control points
   */
  public getRoadmapScore(): number {
    let score;
    if (this.isChild || !this.isCollapsed) {
      score = this.getChaptersGroupScore(this.chaptersGroup);
    } else {
      // Collapsed parent. We show the total of the parent's score and
      // its children score.
      let totalAcknowledged = this.chaptersGroup.acknowledged;
      let totalControlPoints = this.chaptersGroup.total;
      if (this.chaptersGroup.perimeterTypeSections) {
        this.chaptersGroup.perimeterTypeSections.forEach(section => {
          section.chaptersGroup.forEach(childGroup => {
            totalAcknowledged += childGroup.acknowledged;
            totalControlPoints += childGroup.total;
          });
        });
      }
      score = totalControlPoints > 0 ? (100 * totalAcknowledged) / totalControlPoints : 0;
    }
    return score;
  }

  private getChaptersGroupScore(chaptersGroup: ChaptersGroup<Perimeter | AccessLevel | Theme>) {
    if (chaptersGroup.total) {
      return (100 * chaptersGroup.acknowledged) / chaptersGroup.total;
    } else {
      return 0;
    }
  }

  public getLinkedAssetsSentence() {
    const assetCount = this.getAssetCount();
    if (!assetCount) {
      return this.translate.instant("No linked equipement");
    } else {
      return assetCount > 1
        ? assetCount + " " + this.translate.instant("Linked assets")
        : assetCount + " " + this.translate.instant("Linked asset");
    }
  }

  // If a parent is collapsed, we show the total equipment of the parent and its children
  private getAssetCount() {
    if (this.chaptersGroup.hasLevelChildren && this.isCollapsed) {
      return this.chaptersGroup.assetCount + this.chaptersGroup.assetCountOnChildren;
    } else {
      return this.chaptersGroup.assetCount;
    }
  }
}
