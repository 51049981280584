import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { InvestmentsService } from "@services/investments.service";
import { Asset, Investment, InvestmentCategory, formatSearchString, spendingCategoriesSection } from "@structs";
import { Observable, Subscription, of } from "rxjs";

@Component({
  selector: "app-investment-categories",
  templateUrl: "./investment-categories.component.html",
  styleUrls: ["./investment-categories.component.scss"],
})
export class InvestmentCategoriesComponent implements OnInit, OnDestroy {
  @Input() public addMode: boolean = false;
  @Input() public investment: Investment = null;
  @Input() public asset: Asset = null;
  @Input() public showMapping: boolean = false;
  public spendingCategories$: Observable<InvestmentCategory[]>;
  public sections: spendingCategoriesSection[] = [];
  private suggestedSpendingCategories: InvestmentCategory[] = [];
  private subscriptions: Subscription[] = [];
  public searchResults: InvestmentCategory[] = [];

  @Output() public changesHappened = new EventEmitter<{
    spendingCategory?: InvestmentCategory;
    goNext?: boolean;
    hasMapping?: boolean;
    showIcon?: boolean;
  }>();

  constructor(private investmentsService: InvestmentsService, private translate: TranslateService) {}

  ngOnInit() {
    this.subscriptions.push(
      this.investmentsService
        .initInvestmentCategories(this.investment, this.asset)
        .subscribe(({ sections, suggestedSpendingCategories }) => {
          this.sections = sections;
          this.suggestedSpendingCategories = suggestedSpendingCategories;
          // We show the icon and allow the user to switch the display only if there is more
          // than 1 level.
          this.changesHappened.emit({ showIcon: this.sections.length > 1 });

          if (this.investment.investmentCategory) {
            this.initWithSelectedCategory(this.investment.investmentCategory);
          }

          const lastLevelCategories = this.sections[this.sections.length - 1].categories;
          if (this.asset || this.investment.category || this.investment.superCategory) {
            // If we have a technical nomenclature, we look for matches
            this.showMapping = true;
            this.changesHappened.emit({ hasMapping: true });
            if (this.suggestedSpendingCategories.length === 0) {
              // If no mapping for the technical nomenclature, show all
              // spending categories (the last level, I guess)
              this.suggestedSpendingCategories = lastLevelCategories;
            }
          } else {
            // If no technical nomenclature, show all spending categories
            // (the last level, I guess)
            this.suggestedSpendingCategories = lastLevelCategories;
          }
          this.searchResults = this.suggestedSpendingCategories;
        })
    );
  }

  public selectCategory(event, i: number, investmentCategory?: InvestmentCategory) {
    let category;
    if (investmentCategory) {
      category = investmentCategory;
    } else {
      const categoryId = event.detail.value;
      category = this.sections[i].categories.find(cat => cat.id === categoryId);
    }
    this.sections[i].selectedCategory = category;
    if (category.children?.length > 0) {
      this.sections[i + 1].categories = category.children;
      this.sections[i + 1].enabled = true;
    } else {
      this.changesHappened.emit({ spendingCategory: category, goNext: false });
    }
  }

  public selectMappedCategory(category: InvestmentCategory) {
    this.changesHappened.emit({ spendingCategory: category, goNext: true });
  }

  public getSectionLabel(i: number) {
    return this.translate.instant("Level") + " " + i;
  }

  private initWithSelectedCategory(category: InvestmentCategory) {
    let levelCategory = category;
    this.investment.investmentCategory = category;
    for (let i = this.sections.length - 1; i >= 0; i--) {
      this.selectCategory(null, i, levelCategory);
      if (i !== 0 && levelCategory.parentId) {
        // If there is a level above, pick the category's parent for this level
        const parent = this.sections[i - 1].categories.find(cat => cat.id === levelCategory.parentId);
        if (parent) {
          levelCategory = parent;
        } else {
          console.error("parent category not found", levelCategory.parentId);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe);
  }

  public onSearchChanged(event) {
    let searchText = event.target.value;
    if (searchText) {
      const formattedSearchText = formatSearchString(searchText);
      this.searchResults = this.suggestedSpendingCategories.filter(category => {
        const formattedCategoryName = formatSearchString(category.name);
        return formattedCategoryName.includes(formattedSearchText);
      });
    } else {
      this.searchResults = this.suggestedSpendingCategories;
    }
  }

  public onSearchCancelled() {
    this.searchResults = this.suggestedSpendingCategories;
  }
}
