import { formatNumber } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { InvestmentsService } from "@services/investments.service";
import { Asset, Investment, InvestmentSlice, PriceSheet, setLocalStorageItem } from "@structs";
import { Observable, Subscription } from "rxjs";
import { map, tap } from "rxjs/operators";
import { I18nService } from "src/app/services/i18n.service";
import { PriceSheetDetailPage } from "../../price-sheet-detail/price-sheet-detail.page";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "price-sheets-list",
  templateUrl: "./price-sheets-list.component.html",
  styleUrls: ["./price-sheets-list.component.scss"],
})
export class PriceSheetsListComponent implements OnInit, OnDestroy {
  @Input() asset: Asset;
  @Input() investment: Investment;
  @Input() fromAsset: boolean;
  public priceSheets$: Observable<PriceSheet[]>;
  public selectedPriceSheetId: number;
  @Input() addMode: boolean = false;
  @Input() modalMode: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private investmentsService: InvestmentsService,
    private alertCtrl: AlertController,
    private i18nService: I18nService,
    private translate: TranslateService,
    private router: Router,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.priceSheets$ = this.investmentsService.getPriceSheets().pipe(
      map(priceSheets =>
        priceSheets.filter(priceSheet => this.asset && priceSheet.asset_type === this.asset.assetType.id)
      ),
      tap(priceSheets => {
        this.initSelectedPriceSheet(priceSheets);
      })
    );
  }

  private initSelectedPriceSheet(priceSheets: PriceSheet[]) {
    if (this.investment && this.investment.priceSheet) {
      const foundPriceSheet: PriceSheet = priceSheets.find(priceSheet => priceSheet.id === this.investment.priceSheet);
      if (foundPriceSheet) {
        this.selectedPriceSheetId = foundPriceSheet.id;
      }
    } else if (this.addMode) {
      const defaultPriceSheet: PriceSheet = priceSheets.find(priceSheet => priceSheet.is_default);
      if (defaultPriceSheet) {
        this.selectedPriceSheetId = defaultPriceSheet.id;
        this.save(this.selectedPriceSheetId);
      }
    }
  }

  selectPriceSheet(priceSheetId: number) {
    if (priceSheetId === this.selectedPriceSheetId) {
      this.selectedPriceSheetId = null;
      this.save(this.selectedPriceSheetId);
    } else {
      this.selectedPriceSheetId = priceSheetId;
      if (this.investment.investmentBudgetOrigin && this.investment.investmentBudgetOrigin.price_list) {
        const singleYear = this.investment.slices.every(slice => {
          return slice.year === this.investment.slices[0].year;
        });

        if (this.investment.status.hypothesis && singleYear) {
          this.showAutomaticUpdatePriceAlert();
        } else {
          if (!this.addMode) {
            this.showManualUpdatePriceAlert();
          }
          this.save(this.selectedPriceSheetId);
        }
      } else {
        this.save(this.selectedPriceSheetId);
      }
    }
  }

  async showManualUpdatePriceAlert() {
    const updatePriceAlert = await this.alertCtrl.create({
      message: this.translate.instant("Please update the replacement price with the new price sheet"),
      buttons: ["Ok"],
    });
    await updatePriceAlert.present();
  }

  async showAutomaticUpdatePriceAlert() {
    const updatePriceAlert = await this.alertCtrl.create({
      header: this.translate.instant("Reference price sheet"),
      message: this.translate.instant("Do you want to change the investment's budget with the new price sheet ?"),
      buttons: [
        {
          text: "Ok",
          handler: () => {
            this.subscriptions.push(
              this.updateBudget(this.selectedPriceSheetId).subscribe(updatedSlices => {
                this.save(this.selectedPriceSheetId, updatedSlices.slices);
              })
            );
          },
        },
        {
          text: this.translate.instant("Cancel"),
          handler: () => {
            this.save(this.selectedPriceSheetId);
          },
        },
      ],
    });
    await updatePriceAlert.present();
  }

  public async openPriceSheet(priceSheet: PriceSheet, event) {
    event.preventDefault();
    if (this.modalMode) {
      const priceSheetModal = await this.modalCtrl.create({
        component: PriceSheetDetailPage,
        componentProps: {
          priceSheet: priceSheet,
          modalMode: true,
        },
      });
      await priceSheetModal.present();
    } else {
      this.router.navigate(["price-sheet-detail", priceSheet.id]);
    }
  }

  private updateBudget(priceSheetId: number): Observable<{
    slices: Array<InvestmentSlice>;
  }> {
    return this.investmentsService.getPriceSheets().pipe(
      map(priceSheets => {
        const matchingPriceSheet = priceSheets.find(priceSheet => priceSheet.id === priceSheetId);
        if (matchingPriceSheet) {
          const newPrice = matchingPriceSheet.unit_price * this.asset.quantity;
          for (let i = 0; i < this.investment.slices.length; i++) {
            if (this.investment.slices[i].status.id === this.investment.status.id) {
              this.investment.slices[i].price = newPrice;
              break;
            }
          }
          const updatedSlices = this.investmentsService.getSlicesData(this.investment);
          return updatedSlices;
        } else {
          return null;
        }
      })
    );
  }

  save(selectedPriceSheetId: number, updatedSlices?: Array<InvestmentSlice>) {
    if (this.addMode) {
      if (this.modalMode) {
        // We are in the wizard
        this.investment.priceSheet = selectedPriceSheetId;
      } else {
        setLocalStorageItem("priceSheetOfNewInvestment", selectedPriceSheetId);
      }
    } else {
      let data = {};
      data["price_sheet"] = selectedPriceSheetId;
      if (updatedSlices) {
        data["slices"] = updatedSlices;
      }
      this.subscriptions.push(
        this.investmentsService.patchInvestment(this.asset, this.investment, data).subscribe(
          investment => {
            console.log("updated invest", investment);
          },
          err => {
            console.error(err);
          }
        )
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe);
  }
}
