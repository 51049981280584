import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { from, Observable } from "rxjs";

import { Asset, Perimeter } from "../structs/assets";
import { Investment } from "../structs/investments";

import { ErrorsService } from "./errors.service";
import { INVESTMENT_OBJECT, SuccessToastService } from "./success-toast.service";
import { AssetsListPage } from "../pages/assets-list/assets-list.page";
import { AuditService } from "./audit.service";
import { AlertController, ModalController } from "@ionic/angular";
import { InvestmentsService } from "./investments.service";

@Injectable()
export class AttachInvestmentsService {
  private confirmAttachTitle: string = "";
  private confirmAttachMessage: string = "";
  private confirmYesButton: string = "";
  private confirmNoButton: string = "";

  constructor(
    private auditApi: AuditService,
    private successToastCtrl: SuccessToastService,
    private translate: TranslateService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private investmentsService: InvestmentsService,
    private errors: ErrorsService
  ) {
    this.translate.get("Ok").subscribe(yesButton => {
      this.confirmYesButton = yesButton;
    });
    this.translate.get("Cancel").subscribe(noButton => {
      this.confirmNoButton = noButton;
    });
    this.translate.get("Attach asset").subscribe(title => {
      this.confirmAttachTitle = title;
    });
    this.translate.get("Attach XXX to the investment").subscribe(message => {
      this.confirmAttachMessage = message;
    });
  }

  public attachInvestment(investment: Investment, perimeter: Perimeter): Observable<Investment> {
    return from(
      new Promise<Investment>(async (resolve, rejected) => {
        if (investment.assetId === null) {
          let assetsModal = await this.modalCtrl.create({
            component: AssetsListPage,
            componentProps: {
              title: this.translate.instant("Attach an asset"),
              selectMode: true,
              allowAllPerimeters: false,
              investment: investment,
              perimeter: perimeter,
            },
          });
          await assetsModal.present();
          const { data } = await assetsModal.onWillDismiss();
          if (data && data.asset) {
            let confirm = await this.alertCtrl.create({
              header: this.confirmAttachTitle,
              message: this.confirmAttachMessage.replace("XXX", data.asset.label),
              backdropDismiss: false,
              buttons: [
                {
                  text: this.confirmNoButton,
                  handler: () => {
                    resolve(investment);
                  },
                },
                {
                  text: this.confirmYesButton,
                  handler: () => {
                    this.investmentsService.attachInvestmentToAsset(investment, data.asset).subscribe(
                      (investment: Investment) => {
                        const confirmText = this.translate.instant("The investment has been attached to XXX");
                        const editedConfirmText = confirmText.replace("XXX", data.asset.label);
                        this.successToastCtrl.showSuccessToast(INVESTMENT_OBJECT, editedConfirmText);
                        resolve(investment);
                      },
                      err => {
                        rejected(err);
                      }
                    );
                  },
                },
              ],
            });
            await confirm.present();
          } else {
            resolve(investment);
          }
        } else {
          resolve(investment);
        }
      })
    );
  }

  public detachInvestment(investment: Investment, asset: Asset): Observable<Investment> {
    return from(
      new Promise<Investment>(async (resolve, rejected) => {
        if (investment.assetId) {
          let confirm = await this.alertCtrl.create({
            header: this.translate.instant("Detach investment from asset"),
            message: this.translate.instant("Detach investment (confirmation)"),
            backdropDismiss: false,
            buttons: [
              {
                text: this.confirmNoButton,
                handler: () => {
                  resolve(investment);
                },
              },
              {
                text: this.confirmYesButton,
                handler: () => {
                  this.investmentsService.detachInvestmentFromAsset(investment, asset).subscribe(
                    (investment: Investment) => {
                      this.translate.get("The investment has been detached").subscribe(text => {
                        this.successToastCtrl.showSuccessToast(INVESTMENT_OBJECT, text);
                      });
                      resolve(investment);
                    },
                    err => {
                      rejected(err);
                    }
                  );
                },
              },
            ],
          });
          await confirm.present();
        } else {
          resolve(investment);
        }
      })
    );
  }
}
