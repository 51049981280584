import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "pie-chart",
  templateUrl: "./pie-chart.component.html",
  styleUrls: ["./pie-chart.component.scss"],
})
export class PieChartComponent implements OnInit {
  @Input() percentage: number = 0;
  @Input() radius: number = 12;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  getEltStyle(): any {
    let diam: string = "" + 2 * this.radius + "px";
    let rad: string = "" + this.radius + "px";
    let style = "width: " + diam + "; height: " + diam + "; clip: rect(0px, " + rad + ", " + diam + ", 0px);";

    let percentInDeg: number = Math.round((this.percentage * 360) / 100);
    if (percentInDeg > 0) {
      if (percentInDeg > 180) {
        percentInDeg = percentInDeg - 180;
      }
      style += "transform:rotate(" + percentInDeg + "deg)";
    }
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  getSliceStyle(): any {
    let diam: string = "" + 2 * this.radius + "px";
    let rad: string = "" + this.radius + "px";
    let style = "width: " + diam + "; height: " + diam + "; clip: rect(0px, " + diam + ", " + diam + ", " + rad + ");";
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  getContainerStyle(): any {
    let diam: string = "" + 2 * this.radius + "px";
    let style = "height: " + diam + "; margin-right: " + diam + ";";
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  getBackgroundStyle(): any {
    let diam: string = "" + 2 * this.radius + "px";
    let style = "height: " + diam + "; width: " + diam + ";";
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  showFirstHalf(): boolean {
    let percentInDeg: number = Math.round((this.percentage * 360) / 100);
    return percentInDeg > 180;
  }

  getFirstHalfStyle(): any {
    let diam: string = "" + 2 * this.radius + "px";
    let rad: string = "" + this.radius + "px";
    let style = "width: " + diam + "; height: " + diam + "; clip: rect(0px, " + rad + ", " + diam + ", 0px);";
    style += "transform:rotate(180deg)";
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  getZeroMarkerStyle(): any {
    let rad: string = "" + this.radius + "px";
    let style = "";
    if (this.percentage === 0) {
      style = "top: 0px; left: " + rad + ";width: 1px; height: " + rad + ";";
    } else {
      style = "display: none;";
    }
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }
}
