import { Injectable } from "@angular/core";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class BrowserService {
  // Flag for telling other components that the AppSettings page wants to open
  public settingsOpening: boolean = false;

  constructor(
    private inAppBrowser: InAppBrowser,
    private toastCtrl: ToastController,
    private translate: TranslateService
  ) {}

  openUrl(url: string) {
    this.inAppBrowser.create(url, "_system");
  }

  public inBrowser(): boolean {
    //@ts-ignore
    return !window.device || window?.device.platform === "browser";
  }

  /**
   * Shows a toast to inform the user that the feature is unavailable on
   * browser and requires a device.
   */
  public async unavailableFeature() {
    const toast = await this.toastCtrl.create({
      message: this.translate.instant("Feature unavailable on browser"),
      duration: 3000,
    });
    await toast.present();
  }
}
