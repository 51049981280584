<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <app-back-button [modalMode]="modalMode"></app-back-button>
    </ion-buttons>
    <ion-title>{{ "Price sheets" | translate }}</ion-title>
    <ion-buttons slot="end"></ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <loader *ngIf="loading"></loader>
  <ion-backdrop *ngIf="!loading && investment?.status.isClosed"></ion-backdrop>
  <price-sheets-list
    *ngIf="!loading"
    [investment]="investment"
    [asset]="asset"
    [addMode]="addMode"
    [fromAsset]="fromAsset"
    [modalMode]="modalMode"></price-sheets-list>

  <!-- Should be developped in #6018 -->
  <!-- <ion-fab vertical="center" horizontal="end" slot="fixed" *ngIf="asset">
    <ion-fab-button ion-fab (click)="addPriceSheet()" color="pricesheet">
      <i class="fal fa-2x fa-plus"></i>
    </ion-fab-button>
  </ion-fab> -->
</ion-content>
