import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AlertController } from "@ionic/angular";

import { TranslateService } from "@ngx-translate/core";

import { AssetsService } from "../../../services/assets.service";
import { ErrorsService } from "../../../services/errors.service";
import { Asset, AssetType, AssetTypeLevel, Category, SubCategory } from "../../../structs/assets";
import { formatSearchString } from "../../../structs/utils";

import { AssetEditService } from "../../../services/asset-edit.service";
import { Subscription } from "rxjs";
import { ScopeService } from "@services/scope.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "asset-parent-selector",
  templateUrl: "./asset-parent-selector.component.html",
  styleUrls: ["./asset-parent-selector.component.scss"],
})
export class AssetParentSelectorComponent implements OnInit {
  @Input() public asset: Asset;
  @Output() public parentSelected = new EventEmitter<Asset>();
  @Output() public autoParentSelected = new EventEmitter<Asset>();

  public parents: Asset[] = [];
  public filteredParents: Asset[] = [];
  private category: Category = null;
  private subCategory: SubCategory = null;
  private assetType: AssetType = null;
  selectedParent: Asset = null;
  private parentAssetTypes: AssetType[] = [];
  searchText: string = "";
  private subscriptions: Subscription[] = [];

  constructor(
    private assetsApi: AssetsService,
    private assetEditService: AssetEditService,
    private errors: ErrorsService,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private scope: ScopeService
  ) {}

  public ngOnInit(): void {
    this.parents = [];
    this.filteredParents = [];
    this.parentAssetTypes = [];
    this.assetType = this.asset.assetType;
    this.assetsApi.getAssetTypeCategory(this.assetType).subscribe(
      category => {
        this.category = category;
        this.assetsApi.getAssetTypeSubCategory(this.assetType).subscribe(subCategory => {
          this.subCategory = subCategory;
          this.assetsApi.getParentAssetTypes(this.assetType).subscribe(
            (parentAssetTypes: AssetType[]) => {
              this.parentAssetTypes = parentAssetTypes;
              this.asset.category = category;
              this.asset.subCategory = subCategory;
              this.assetsApi.getPotentialParents(this.asset).subscribe(
                (parents: Asset[]) => {
                  this.parents = parents;
                  this.filteredParents = [].concat(this.parents);
                  if (
                    (this.asset.assetType.level === AssetTypeLevel.LEVEL_COLLECTION_ITEM ||
                      this.asset.assetType.level === AssetTypeLevel.LEVEL_COMPONENT) &&
                    this.parents.length === 0 &&
                    this.parentAssetTypes.length > 0
                  ) {
                    // If collection item ("élément d'un ensemble") has no potential parent ("Ensemble de")
                    // propose to create it automatically
                    this.addParent();
                  }
                },
                err => {
                  this.errors.signalError(err);
                }
              );
            },
            err => {
              this.errors.signalError(err);
            }
          ),
            err => {
              this.errors.signalError(err);
            };
        });
      },
      err => {
        this.errors.signalError(err);
      }
    );
  }

  public onSearchCancelled() {
    this.searchText = "";
  }

  public onSearchChanged() {
    this.selectedParent = null;
    this.parentSelected.emit(null);
    if (this.searchText) {
      // remove accents and make lower case, for better results
      let formattedSearchText = formatSearchString(this.searchText);
      this.filteredParents = this.parents.reduce((matching: Asset[], elt: Asset) => {
        if (
          formatSearchString(elt.label).indexOf(formattedSearchText) >= 0 ||
          formatSearchString(elt.assetType.name).indexOf(formattedSearchText) >= 0
        ) {
          // the asset is matching with the search : add it as a result
          matching.push(elt);
        }
        return matching;
      }, []);
    } else {
      this.filteredParents = [].concat(this.parents);
    }
  }

  public selectParent(parent: Asset, auto: boolean) {
    this.selectedParent = parent;
    if (!auto) {
      this.parentSelected.emit(parent);
    } else {
      this.autoParentSelected.emit(parent);
    }
  }

  duplicateAsset(asset) {
    this.subscriptions.push(
      this.scope.getCurrentMultiPerimeter().subscribe(async site => {
        const assetPerimeter = site.sub_perimeters.find(p => p.id === asset.building.monosite_perimeter.id);
        // Prevent cloning if asset type isn't allowed (asset type "...à classer")
        if (
          assetPerimeter &&
          this.assetsApi.isAssetTypeAllowedForPerimeter(asset.assetType, assetPerimeter.perimeterType, site.cluster)
        ) {
          const parentLabel: string = "(" + this.translate.instant("Copy of") + ") " + asset.label;
          this.assetEditService
            .saveNewParentAsset(
              this.category,
              this.subCategory,
              asset.assetType,
              asset.zone,
              asset.building,
              asset.step,
              parentLabel
            )
            .subscribe(
              newParent => {
                this.parents.push(newParent);
                this.filteredParents = [].concat(this.parents);
              },
              err => {
                this.errors.signalError(err);
              }
            );
        } else {
          const cantCloneAlert = await this.alertCtrl.create({
            message: this.translate.instant("This asset type can't be duplicated"),
            buttons: ["Ok"],
          });
          await cantCloneAlert.present();
        }
      })
    );
  }

  private createParent(assetType) {
    const asset = this.assetEditService.asset;
    this.assetEditService
      .saveNewParentAsset(this.category, this.subCategory, assetType, asset.zone, asset.building, asset.step)
      .subscribe(
        newParent => {
          this.parents.push(newParent);
          this.filteredParents = [].concat(this.parents);
          this.selectParent(newParent, true);
        },
        err => {
          this.errors.signalError(err);
        }
      );
  }

  public async addParent() {
    if (this.parentAssetTypes.length > 0) {
      const assetType = this.parentAssetTypes[0];
      let confirm = await this.alertCtrl.create({
        header: this.translate.instant("Parent asset"),
        message: this.translate.instant("Creating your asset will automatically create a parent"),
        backdropDismiss: false,
        buttons: [
          {
            text: this.translate.instant("Cancel"),
            handler: () => {},
          },
          {
            text: this.translate.instant("OK"),
            handler: () => {
              this.createParent(assetType);
            },
          },
        ],
      });
      await confirm.present();
    }
  }
}
