<div class="pie-container" [style]="getContainerStyle()">
  <div class="pie-background" [style]="getBackgroundStyle()"></div>
  <div class="pie-slice" *ngIf="showFirstHalf()">
    <div class="pie-elt" [style]="getFirstHalfStyle()"></div>
  </div>
  <div class="pie-slice" [ngClass]="showFirstHalf() ? 'slice2' : ''" [style]="getSliceStyle()">
    <div class="pie-elt" [style]="getEltStyle()"></div>
  </div>
  <div class="pie-zero-marker" [style]="getZeroMarkerStyle()"></div>
</div>
