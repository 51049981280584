import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observer } from "rxjs";
import * as moment from "moment";
import { DocumentRequirement, PerimeterDocument, DocumentType, Document } from "@structs";
import { AlertController, IonSelect, ModalController, NavController, NavParams } from "@ionic/angular";
import { DocumentsService } from "../../../services/documents.service";
import { ErrorsService } from "../../../services/errors.service";
import { AuthService } from "../../../services/auth.service";
import { BackendService } from "@services/backend.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "page-documents-add",
  templateUrl: "documents-add.html",
  styleUrls: ["./documents-add.scss"],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DocumentsAddPage implements OnInit {
  public title: string;

  // perimeter mode
  // Migration notes: these navparams is now an Input
  @Input() perimeterId: number;
  @Input() perimeterName: string;
  @ViewChild("documenttypeselect", { static: true })
  public documentTypeSelect: IonSelect;

  public docTypeId: number;
  public docTypeName: string;
  public fixedPerimeterId: number;
  public expiry_date: Date = null;

  // investment mode
  public investmentId: number = null;
  public investmentName: string;

  // asset mode
  public assetId: number = null;
  public assetName: string;

  public allRequirements: Array<DocumentRequirement> = [];
  public perimeters: Array<PerimeterDocument> = [];
  public documentTypes: Array<DocumentType> = [];
  public selectedPerimeterId: number;
  public selectedDocumentTypeId: number;
  public label: string;
  public link: string;
  public comments: string;

  // initiative mode
  @Input() public initiativeId: number = null;

  completeAllFieldsTitle: string;
  completeAllFieldsMessage: string;
  titleField: string;
  linkField: string;
  linkIsValid: boolean = true;
  canValidate: boolean;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public translate: TranslateService,
    private docService: DocumentsService,
    private errors: ErrorsService,
    public alertCtrl: AlertController,
    private auth: AuthService,
    private modalController: ModalController
  ) {
    this.perimeterId = this.navParams.get("perimeterId") || null;
    this.perimeterName = this.navParams.get("perimeterName") || null;
    this.docTypeId = this.navParams.get("docTypeId") || null;
    this.docTypeName = this.navParams.get("docTypeName") || null;
    this.fixedPerimeterId = this.navParams.get("fixedPerimeterId") || null;
    this.investmentId = this.navParams.get("investmentId") || null;
    this.investmentName = this.navParams.get("investmentName") || null;
    this.assetId = this.navParams.get("assetId") || null;
    this.assetName = this.navParams.get("assetName") || null;
  }

  ionViewDidLoad() {}

  ngOnInit() {
    this.translate.get("Save error").subscribe(text => {
      this.completeAllFieldsTitle = text;
    });
    this.translate.get("Please complete all fields to continue").subscribe(text => {
      this.completeAllFieldsMessage = text;
    });
    this.translate.get("Link").subscribe(text => {
      this.linkField = text;
    });
    this.translate.get("Title").subscribe(text => {
      this.titleField = text;
    });

    if (this.perimeterId && this.perimeterName != null && this.perimeterName != "") {
      this.title = this.perimeterName + " > ";
    } else if (this.investmentId && this.investmentName != null && this.investmentName != "") {
      this.title = this.investmentName + " > ";
    } else if (this.assetId && this.assetName != null && this.assetName != "") {
      this.title = this.assetName + " > ";
    }
  }

  public ionViewDidEnter(): void {
    this.getDocumentInformation();
    this.documentTypeSelect.open();
  }

  somethingChanged() {
    this.refreshOptions();
  }

  refreshOptions() {
    this.perimeters = [];
    this.documentTypes = [];

    this.allRequirements.forEach(requirement => {
      if (this.investmentId !== null || this.assetId !== null || this.initiativeId !== null) {
        if (this.documentTypes.findIndex(dt => dt.id == requirement.document_type.id) == -1) {
          this.documentTypes.push(requirement.document_type);
        }
      } else {
        if (this.perimeters.findIndex(p => p.id == requirement.perimeter.id) == -1) {
          if (this.selectedDocumentTypeId != null) {
            // only add this perimeter if it has the selected document type
            if (requirement.document_type.id == this.selectedDocumentTypeId) {
              this.perimeters.push(requirement.perimeter);
            }
          } else {
            this.perimeters.push(requirement.perimeter);
          }
        }

        if (this.documentTypes.findIndex(dt => dt.id == requirement.document_type.id) == -1) {
          if (this.selectedPerimeterId != null) {
            // only show document types for the given perimeter
            if (requirement.perimeter.id == this.selectedPerimeterId) {
              this.documentTypes.push(requirement.document_type);
            }
          } else {
            this.documentTypes.push(requirement.document_type);
          }
        }
      }
    });

    this.documentTypes.sort((docType1, docType2) => docType1.order - docType2.order);

    if (this.documentTypes.length == 1) {
      this.selectedDocumentTypeId = this.documentTypes[0].id;
    }
  }

  private getDocumentInformation(): void {
    if (this.perimeterId != null) {
      this.docService.getRequirementsForPerimeter(this.perimeterId).subscribe(
        (requirements: Array<DocumentRequirement>) => {
          this.allRequirements = requirements;

          if (this.fixedPerimeterId != null) {
            this.selectedPerimeterId = this.fixedPerimeterId;
          }
          if (this.docTypeId != null) {
            this.selectedDocumentTypeId = this.docTypeId;
          }

          this.refreshOptions();
        },
        err => {
          this.errors.signalError(err);
        }
      );
    } else {
      // investment or asset mode
      this.allRequirements = this.navParams.get("allRequirements");
      this.refreshOptions();
    }
  }

  public save(event: Observer<boolean>): void {
    let doc = new Document(null, this.label, null, null, this.link, "", this.comments, this.expiry_date);

    let docType = null;
    if (this.initiativeId) {
      docType = this.documentTypes.find(type => type.id === this.selectedDocumentTypeId);
    }
    for (let i = 0; i < this.allRequirements.length; i++) {
      if (this.allRequirements[i].document_type.id == this.selectedDocumentTypeId) {
        docType = this.allRequirements[i].document_type;
        break;
      }
    }

    this.docService
      .saveDocument(docType, doc, this.selectedPerimeterId, this.investmentId, this.assetId, this.initiativeId)
      .subscribe(
        docId => {
          doc.id = docId;
          doc.created_on = new Date().toISOString();
          this.auth.getCurrentUser().then(user => {
            doc.created_by = user.getDisplayName();
            this.modalController.dismiss();
            event.next(true);
          });
        },
        async err => {
          console.error("err", err);
          let message: string = err.statusText;
          if (err.error.hasOwnProperty("document")) {
            if (err.error.document.hasOwnProperty("link")) {
              message = this.linkField + ": " + err.error.document.link[0];
            } else if (err.error.document.hasOwnProperty("title")) {
              message = this.titleField + ": " + err.error.document.title[0];
            }
          }

          let alert = await this.alertCtrl.create({
            header: this.completeAllFieldsTitle,
            message: message,
            buttons: ["Ok"],
          });

          await alert.present();
          event.next(false);
        }
      );
  }

  dayShortNames(): Array<string> {
    return moment.weekdaysShort();
  }

  dayNames(): Array<string> {
    return moment.weekdays();
  }

  monthShortNames(): Array<string> {
    return moment.monthsShort();
  }

  monthNames(): Array<string> {
    return moment.months();
  }

  getMinDate(): string {
    // current year - 1
    let year = moment().year() - 1;
    const minDate = moment(new Date(year, 0, 1)); // 01/01/YYYY
    return minDate.format("YYYY-MM-DD");
  }

  getMaxDate(): string {
    // current year + 10
    let year = moment().year() + 10;
    const maxDate = moment(new Date(year, 11, 31)); // 31/12/YYYY
    return maxDate.format("YYYY-MM-DD");
  }

  async goBackToDocumentPage() {
    await this.modalController.dismiss();
  }

  public isDocumentValid(): boolean {
    return (
      (!!this.investmentId || !!this.selectedPerimeterId || !!this.assetId || !!this.initiativeId) &&
      !!this.selectedDocumentTypeId &&
      !!this.label &&
      !!this.link &&
      !!this.canValidate
    );
  }

  public validateLink(link): void {
    // we only validate the protocol, not the full URL itself
    const urlPattern = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
    this.linkIsValid = urlPattern.test(link);
    this.canValidate = this.linkIsValid;
  }

  public blur() {
    this.canValidate = false;
  }
}
